
import { Vue, Component } from "vue-property-decorator";
import FormErrors, { FieldsGroup } from "@/utils/FormErros";
import { safeAsync } from "@/utils/AsyncUtil";
import PageSection from "@/components/Common/PageSection.vue";
import { authStore, zoneStore } from "@/store/typed";
import { showErrorToast } from "@/utils/Toast";
import LoadingButton from "@/components/Common/LoadingButton.vue";
import VFormGroup from "@/components/Common/VFormGroup.vue";
import { currentUserStore } from "../store/typed";

@Component({
  components: {
    LoadingButton,
    PageSection,
    VFormGroup,
  },
})
export default class LoginPage extends Vue {
  private loading = false;

  private fields: FieldsGroup = {
    username: "",
    password: "",
  };

  private invalidCredentials = false;

  get currentUser() {
    return currentUserStore.currentUser;
  }

  private async onSubmit() {
    this.loading = true;
    const user = {
      email: this.fields.username as string,
      password: this.fields.password as string,
    };
    const [data] = await safeAsync(authStore.login(user));

    if (data) {
      await zoneStore.loadRealm();
      if (this.currentUser.isOccUser) {
        await this.$router.push({ name: "watcher_area_home" });
      } else {
        await this.$router.push({ name: "info_home" });
      }
    } else {
      this.loading = false;
      this.invalidCredentials = true;
      showErrorToast("Errore durante la login");
    }
  }
}
