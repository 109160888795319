import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { httpStore } from "@/store/typed";

export enum RegistrationTags {
  RegistrationCreate = "RegistrationCreate",
}

@Component
export default class RegistrationMixin extends Vue {
  async createRegistration(data: any): Promise<any> {
    const url = `${SD_BASE_API_URL}/registration_requests`;

    return httpStore.request({
      tag: RegistrationTags.RegistrationCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<any>;
  }
}
