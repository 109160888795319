import { User } from "@/models";
import { httpStore } from "@/store/typed";
import { SD_BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";

export enum ConfirmationTags {
  ValidateToken = "ValidateEmail",
  ConfirmWithPassword = "ConfirmWithPassword",
  SendEmail = "SendEmail",
}

const BASE_CONFIRMATION_URL = SD_BASE_API_URL + "/confirmation";

@Component
export default class ConfirmationMixin extends Vue {
  validateToken(confirmationToken: string) {
    const url = `${BASE_CONFIRMATION_URL}/validate?confirmationToken=${confirmationToken}`;

    return httpStore.request({
      tag: ConfirmationTags.ValidateToken,
      url: url,
      method: "GET",
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<User>;
  }

  confirmWithPassword(confirmationToken: string, password: string) {
    const data = { confirmationToken, password };
    const url = `${BASE_CONFIRMATION_URL}/with_password`;
    return httpStore.request({
      tag: ConfirmationTags.ConfirmWithPassword,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<any>;
  }

  sendEmail(email: string) {
    const data = { email };
    const url = `${BASE_CONFIRMATION_URL}/send_email`;
    return httpStore.request({
      tag: ConfirmationTags.SendEmail,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<any>;
  }
}
