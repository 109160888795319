export interface FieldError {
  field: string;
  message: string;
}

export interface FieldsGroup {
  [propertyName: string]: string | boolean;
}

export default class FormErrors {
  private errors: FieldError[];

  constructor() {
    this.errors = [];
  }

  addError(field: string, message: string) {
    if (this.hasError(field)) {
      return;
    }

    this.errors.push({ field: field, message: message });
  }

  hasError(field: string) {
    const error = this.errors.find((el: FieldError) => el.field === field);

    return error ? true : false;
  }

  getErrorMessage(field: string) {
    const error = this.errors.find((el: FieldError) => el.field === field);

    return error ? error.message : null;
  }

  clearErrors() {
    this.errors = [];
  }

  errorsExist() {
    return this.errors.length ? true : false;
  }
}
