import Vue from "vue";

export function showToast(
  message: string,
  config: { position?: string; type?: string; duration?: number } = {
    position: "bottom-right",
    type: "success",
  }
) {
  Vue.toasted.show(message, {
    position: config.position || "bottom-right",
    type: config.type || "success",
    duration: config.duration || 3000,
    keepOnHover: true,
  } as any);
}

export function showErrorToast(
  message: string,
  config: { position?: string; type?: string; duration?: number } = {
    position: "bottom-right",
  }
) {
  Vue.toasted.show(message, {
    position: config.position || "bottom-right",
    type: "error",
    duration: config.duration || 3000,
    keepOnHover: true,
  } as any);
}
