
import { Component, Mixins } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import ConfirmatiomMixin, {
  ConfirmationTags,
} from "@/mixins/http/ConfirmationMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import VFormGroup from "@/components/Common/VFormGroup.vue";
import { authStore, httpStore, zoneStore } from "../store/typed";
import { showErrorToast, showToast } from "@/utils/Toast";

@Component({
  components: {
    PageSection,
    VFormGroup,
  },
})
export default class ConfirmWithPassword extends Mixins(ConfirmatiomMixin) {
  private invalidToken = false;
  private password: string | null = null;
  private repeatPassword: string | null = null;
  private email: string | null = null;
  private resendEmail: string | null = null;
  private emailResent = false;
  async validate() {
    const [data, errors] = await safeAsync(
      this.validateToken(this.token as string)
    );

    if (data) {
      this.invalidToken = false;
      this.email = data.email;
    } else {
      this.invalidToken = true;
    }
  }

  async confirm() {
    const [data, errors] = await safeAsync(
      this.confirmWithPassword(this.token as string, this.password)
    );

    if (data) {
      showToast("Profilo confermato. Accesso in corso");
      this.login();
    } else {
      showErrorToast("Errore nella conferma del profilo");
    }
  }

  get validating() {
    return httpStore.status[ConfirmationTags.ValidateToken]?.loading;
  }

  get confirming() {
    return httpStore.status[ConfirmationTags.ConfirmWithPassword]?.loading;
  }

  async login() {
    const user = {
      email: this.email as string,
      password: this.password as string,
    };
    const [data] = await safeAsync(authStore.login(user));

    if (data) {
      zoneStore.loadRealm();
      await this.$router.push({ name: "info_home" });
    }
  }

  get valid() {
    return (
      this.repeatPassword === this.password &&
      this.repeatPassword &&
      this.password
    );
  }

  async resend() {
    const [data, errors] = await safeAsync(this.sendEmail(this.resendEmail));

    if (data) {
      this.emailResent = true;
      showToast("Mail inviata");
    } else {
      showErrorToast(
        "Non è stato possibile riinviare la mail. L'indirizzo è corretto?"
      );
    }
  }

  mounted() {
    this.validate();
  }

  get token() {
    return this.$route.query.confirmation_token;
  }
}
