import { DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";
import Vue from "vue";
import { httpStore } from "@/store/typed";

const errorsStore = new Map<HTMLElement, Vue>();

const withErrors: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    const data = new Vue({
      data() {
        return { tag: binding.value.tag, field: binding.value.field };
      },
    });

    data.$watch(
      () => httpStore.status[data.$data.tag],
      (requestData) => {
        if (!requestData) {
          return;
        }
        el.classList.remove("has-error");

        const { statusCode, errors } = requestData;
        if (statusCode !== 422 || !errors?.details?.[data.$data.field]) {
          return;
        }

        el.classList.add("has-error");

        let message = el.getElementsByClassName("invalid-message")[0];
        if (!message) {
          const input = el.getElementsByTagName("input")[0];
          message = document.createElement("span");
          message.className = "invalid-message";
          if (input.parentElement) {
            input.parentElement.insertBefore(message, input.nextSibling);
          }
        }
        message.innerHTML = requestData.errors?.errors[data.$data.field];
      },
      { deep: true }
    );

    errorsStore.set(el, data);
  },
  unbind(el: HTMLElement) {
    const data = errorsStore.get(el);
    data?.$destroy();
    errorsStore.delete(el);
  },
};

export default withErrors;
