
import { Component, Mixins } from "vue-property-decorator";
import FormErrors, { FieldsGroup } from "@/utils/FormErros";
import RegistrationMixin, {
  RegistrationTags,
} from "@/mixins/http/RegistrationMixin";
import PageSection from "@/components/Common/PageSection.vue";
import ThankYou from "@/components/Common/ThankYou.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import { showToast, showErrorToast } from "@/utils/Toast";
import { validateEmail } from "@/utils/Validations";
import VFormGroup from "@/components/Common/VFormGroup.vue";

import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";

@Component({
  components: {
    PageSection,
    ThankYou,
    VFormGroup,
  },
})
export default class RegistrationPage extends Mixins(RegistrationMixin) {
  private fields: FieldsGroup = {
    name: "",
    administration: "",
    pec: "",
    phone: "",
  };
  private sent = false;
  private formErrors = new FormErrors();
  private requiredFields = ["name", "pec", "administration", "phone"];

  @RegisterHttp(RegistrationTags.RegistrationCreate)
  readonly readRequest!: RequestData;

  get httpTag() {
    return RegistrationTags.RegistrationCreate;
  }

  private async onSubmit() {
    this.checkErrors();

    if (this.formErrors.errorsExist()) {
      return;
    }

    const payload = {
      name: this.fields.name as string,
      administration: this.fields.administration as string,
      pec: this.fields.pec as string,
      phone: this.fields.phone as string,
    };

    const [data, errors] = await safeAsync(
      await this.createRegistration(payload)
    );

    if (errors) {
      showErrorToast("Si è verificato un errore");
    } else {
      this.reset();
      this.sent = true;
      showToast("Richiesta di registrazione inviata!");
    }
  }

  reset() {
    this.fields = {
      name: "",
      administration: "",
      pec: "",
      phone: "",
    };
  }

  private checkErrors() {
    this.formErrors.clearErrors();

    this.requiredFields.forEach((el: string) => {
      if (!this.fields[el]) {
        this.formErrors.addError(el, "Campo obbligatorio");
      }
    });

    if (!validateEmail(this.fields.pec as string)) {
      this.formErrors.addError("pec", "Email non valida");
    }
  }
}
