
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ThankYou extends Vue {
  @Prop({ required: true }) readonly title!: string;
  @Prop({ default: "it-check-circle" }) readonly icon!: string;
  @Prop({ required: false }) readonly action!: string;
  @Prop({ required: true }) readonly message!: string;
  @Prop({ default: "center" }) readonly alignment!: "center" | "left" | "right";
  get alignmentClass() {
    return `text-${this.alignment}`;
  }

  emitAction() {
    this.$emit("action");
  }
}
